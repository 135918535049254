let baseQ = [
	{
		id: 'B1',
		title: '所属区域',
		selTitle: '所属区域', 
		type: 'picker',
		pickName:'',
		pickValue:[''],
		baseValue:10,
		options: [[
			{name:'两江新区', value:'B1-ljxq-1.5'},
			{name:'沙坪坝', value:'B1-spb-1'},
			{name:'渝中', value:'B1-yz-1.2'},
			{name:'江北区', value:'B1-jbq-1.2'},
			{name:'九龙坡', value:'B1-jlp-1'},
			{name:'巴南区', value:'B1-bnq-1'},
			{name:'南岸区', value:'B1-naq-1'},
			{name:'江津', value:'B1-jj-1.2'},
			{name:'渝北', value:'B1-yb-1.5'},
			{name:'北碚', value:'B1-bb-0.8'},
			{name:'大渡口', value:'B1-ddk-1.1'},
			{name:'璧山', value:'B1-bs-1.2'},
			{name:'永川', value:'B1-rc-1.2'},
			{name:'合川', value:'B1-hc-1.2'},
			{name:'高新区', value:'B1-gxq-1.2'},
			{name:'忠县', value:'B1-zx-1'},
			{name:'双桥', value:'B1-sq-1'},
			{name:'潼南', value:'B1-tn-1.3'},
			{name:'万盛', value:'B1-ws-1'},
			{name:'南川', value:'B1-nc-1'},
			{name:'垫江', value:'B1-dj-1'},
			{name:'涪陵', value:'B1-fl-1.5'},
			{name:'綦江', value:'B1-qj-1'},
			{name:'大足', value:'B1-dz-1'},
			{name:'长寿', value:'B1-cs-1.3'},
			{name:'万州', value:'B1-wz-1.3'},
			{name:'梁平', value:'B1-lp-1.3'},
			{name:'其他', value:'B1-qt-1'}
		]]
	},
	{
		id: 'B2',
		title: '所属行业',
		selTitle: '所属行业', 
		type: 'picker',
		pickName:'',
		pickValue:[''],
		options:[[
			{name:'农、林、牧、渔业', value:'B2-B21'},
			{name:'采矿业', value:'B2-B22'},
			{name:'制造业', value:'B2-B23'},
			{name:'电力、热力、燃气及水生产和供应业', value: 'B2-B24'},
			{name:'建筑业', value:'B2-B25'}, 
			{name:'批发和零售业', value:'B2-B26'}, 
			{name:'交通运输、仓储和邮政业' , value:'B2-B27'},
			{name:'住宿和餐饮业' , value:'B2-B28'},
			{name:'信息传输、软件和信服务业', value:'B2-B29'},
			{name:'金融、保险业', value:'B2-B30'},
			{name:'房地产业', value:'B2-B31'},
			{name:'租赁和商务服务业', value:'B2-B32'},
			{name:'科学研究和技术服务业', value:'B2-B33'},
			{name:'水利、环境和公共设施管理业', value:'B2-B34'},
			{name:'居民服务、修理和其他服务业', value:'B2-B35'},
			{name:'教育', value:'B2-B36'},
			{name:'卫生和社会工作', value:'B2-B37'},
			{name:'文化、体育和娱乐业', value:'B2-B38'}
		]]
	},
	{
		id: 'B3',
		title: '上年营收',
		selTitle: '上年报税营收', 
		type: 'picker',
		pickName:'',
		pickValue:[''],
		options:[[
			{name:'成立不足一年', value:'B3-1'},
			{name:'0~29万元(亏损)', value:'B3-2'},
			{name:'29~199万元(盈利)', value:'B3-3'},
			{name:'200~1000万元(亏损)', value:'B3-4'},
			{name:'200~1000万元(盈利)', value:'B3-5'},
			{name:'>1000万元(亏损)', value:'B3-6'},
			{name:'>1000万元(盈利)', value:'B3-7'}
		]]
	},
	{
		id: 'B4',
		title: '社保人数',
		selTitle: '社保人数', 
		type: 'range',
		rangeValue: 0
	},
	{
		id: 'B5',
		title: '农民工数',
		selTitle: '农民工数', 
		type: 'range',
		rangeValue: 0
	},
	{
		id: 'B6',
		title: '大学生数',
		selTitle: '大学生数',
		type: 'range',
		rangeValue: 0
	}
];

export default baseQ;