<template>
  <div style="height: 100%">
  <div class="bk">
    <div class="page">
   <!-- popup picker-->
   <div v-transfer-dom>
      <popup
        v-model="showPopPicker"
        class="vux-popup-picker">
          <popup-header
          :left-text="cancle"
          :right-text="confirm"
          @on-click-left="onPopHide(false)"
          @on-click-right="onPopHide(true)"
          :title="title"></popup-header>
          <picker
          ref="picker"
          :data="pickerData"
          v-model="pickerValue"
          @on-change="onPickerChange"></picker>
      </popup>
    </div>
  <!-- popup picker-->

  <!-- popup range-->
  <div v-transfer-dom>
    <popup
      v-model="showPopRange"
      class="vux-popup-picker">
        <popup-header
        :left-text="cancle"
        :right-text="confirm"
          @on-click-left="onPopRangeHide(false)"
          @on-click-right="onPopRangeHide(true)"
          :title="title"></popup-header>
            <div style="height:200px;padding:30px;background-color:#fff;" id="rangeContainer">
              <div class="range-title">
                <van-icon name="minus"  @click="onRangeSub"/>
                <div>{{rangeTempValue}}
                  <span style="font-size:12px;color:#000">人</span>
                </div>
                <van-icon name="plus" @click="onRangeAdd"/>
              </div>
              <x-range style="padding:0 2px" v-model="rangeTempValue" :min='1' :max='200'>
                <div slot="start" style="padding-right:4px">{{rangeValueMin}}</div>
                <div slot="end" style="padding-left:4px">{{rangeValueMax}}</div>
              </x-range>
            </div>
    </popup>
  </div>
  <!-- popup range-->

    <div class="logo">
    </div>
    <div style="height:190px">
    <div class="result" :class="{isFixed: isFixed}" >
      <div class="chart" style="position:relative" ref="chartContainer">
        <v-chart ref="circleChart"
          :data="chartData"
           :padding="[10, 'auto']"
          :height="chartHeight"
          :width="chartWidth"
          >
          <v-tooltip disabled />
          <v-pie :radius="1" :inner-radius="0.65" series-field="name" :colors="['#01becc','#f0573d','#ffb400','#ff7d2e','#9a76be']"/>
          <v-legend disabled />
          <div class="bt">
            <div class="title">最高补贴</div>
            <ICountUp class="fee"
              :startVal="0"
              :endVal="totalFee"
              :decimals="1"
              :duration="1"
              :options="dOptions"
            />
            <div class="unit">万</div>
        </div>
        </v-chart>

      </div>
      <div class="xq-container">
        <ul class="xq-list">
          <li><span class="dot rsj"/>人社局<span class="percent">{{rsjRatio}}%</span></li>
          <li><span class="dot kw"/>科委<span class="percent">{{kwRatio}}%</span></li>
          <li><span class="dot jxw"/>经信委<span class="percent">{{jxwRatio}}%</span></li>
          <li><span class="dot zxqy"/>中小企业局<span class="percent">{{zxqyjRatio}}%</span></li>
          <li><span class="dot qt" />其他<span class="percent">{{qtRatio}}%</span></li>
        </ul>
          <a class="xq-btn" v-bind:class="{'xq-btn-active':projectNum}" @click="goList">
            查看详情
            <badge class="xq-badge" :text="projectNum" v-if="projectNum != 0"></badge>
          </a>
      </div>
    </div>
  </div>

    <div class="question">
      <div class="q-base">
        <div class="q-item" :key="item.title" v-for="(item, index) in baseQ">
          <span class="q-item-title">{{item.title}}</span>
          <span v-if='item.type == "picker"' class="q-item-sel" @click="onBaseQClick(index, $event)">
            {{item.pickName}}
            <van-icon name="arrow-down" class="q-item-arrow-down"/>
            <!-- <x-icon type="ios-arrow-down" size="20" class="q-item-arrow-down"></x-icon> -->
          </span>
          <span v-else class="q-item-sel" @click="onBaseQClick(index, $event)">
            {{item.rangeValue}}人
            <van-icon name="arrow-down" class="q-item-arrow-down"/>
            <!-- <x-icon type="ios-arrow-down" size="20" class="q-item-arrow-down"></x-icon> -->
          </span>
        </div>
      </div>
      <div class="divider"/>
      <div v-if="!showDetailQ" @click="onMoreClick" style="height:40px">
        <div class="more">点击下拉箭头，评估精准补贴
            <van-icon name="arrow-down" class="more-arrow-down"/>
            <!-- <x-icon type="android-arrow-down" size="15" class="more-arrow-down"></x-icon> -->
        </div>
      </div>
      <div v-else class="q-detail">
        <div class="q-detail-item"  :key="item.id" v-for="(item) in detailQ">
          <div class="q-detail-title">{{item.title}}</div>
          <div class="q-detail-opts">
            <div class="q-detail-opts-item" :key="opt.id" v-for="opt in item.options">
              <input :id="opt.id" type="radio"
                    :name="item.id"
                    :value="opt.value"
                    v-model="item.value"
                    @change="onRadioClick($event)">
              <label :for="opt.id">{{opt.name}}</label>
            </div>
          </div>
        </div>
        <div class="xq-bottom" v-if="projectNum != 0" @click="goList">
          可获得<span class="project-num">{{projectNum}}</span>项补助，<span class="xq-link">点击查看详情</span>
        </div>
      </div>
    </div>
  </div>
  <!-- ball -->
  <div class="ball-container">
    <transition name="drop"
          @before-enter="_beforeDrop"
          @enter="_dropping"
          @after-enter="_afterDrop">
      <div v-show="ball.show" class="ball" v-bind:css="false">
        <div class="inner inner-hook"></div>
      </div>
    </transition>
  </div>
  <!-- ball -->
 </div>
 <div class="footer">
    <div @click="onClearLS">@2018 soucee 重庆搜策网络有限公司</div>
 </div>
<audio ref="audio" src="@/assets/golden-coin.mp3"></audio>
</div>
</template>

<script>
import { Icon } from 'vant';
import { TransferDom, Popup, PopupHeader, Picker, Badge } from 'vux'
import { mapState } from 'vuex'
import XRange from './XRange.vue'
import throttle from 'lodash/throttle'
import ICountUp from 'vue-countup-v2'
import { VChart, VTooltip, VLegend, VPie } from 'vux'
import baseQ from '../../../data/policy/BaseQ.js'
import detailQ from '../../../data/policy/DetailQ.js'
import rules from '../../../data/policy/Rules.js'


const chartData = [
  { name: '人社局', percent: 25, a: '1' },
  { name: '科委', percent: 25, a: '1' },
  { name: '经信委', percent: 25, a: '1' },
  { name: '中小企业局', percent: 25, a: '1' },
  { name: '其他', percent: 25, a: '1' },

]

export default {
  name: 'Calc',
  directives: {
    TransferDom
  },
  components: {
    Popup,
    PopupHeader,
    Picker,
    ICountUp,
    XRange,
    Badge,
    VChart,
    VTooltip,
    VLegend,
    VPie,
    [Icon.name]: Icon
  },
  data() {
    return {
      clearLSCount: 0,
      ball:{
        show: false
      },
      dropBalls:[],
      curTarget: null,

      offsetTop: 10,
      showPopPicker: false,
      confirm: '确定',
      cancle: '取消',
      title: '选择地区',

      // 弹出选择器
      pickerData:[],
      pickerValue: [''],

      // 弹出范围选择器
      showPopRange: false,
      rangeValueMin:0,
      rangeValueMax:200,
      rangeTempValue:0,
      rangeValue: 0,

      isFixed: false,

      baseIdx: 0,
      baseQ: baseQ, // 基本题目
      detailQ: detailQ, // 详细题目
      rules: rules, // 项目规则

      detailQA: [],

      throttleScroll: null,
      dOptions: {
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
          prefix: '',
          suffix: ''
      },
      chartHeight: 0,
      chartWidth: 0,
      chartData
  }},
  computed: mapState('policy',[
    'reload',
    'project',
    'projectNum',
    'showDetailQ',
    'totalFee',
    'rsjRatio',
    'rsjFee',
    'kwRatio',
    'kwFee',
    'jxwRatio',
    'jxwFee',
    'zxqyjRatio',
    'zxqyjFee',
    'qtRatio',
    'qtFee',
    'questionProjectMap'
  ]),
  created() {
    console.log('created')
    this.$store.commit('policy/mapQuestionProject')
  },
  mounted() {
    console.log('mounted')
    this.chartHeight = this.$refs.chartContainer.offsetHeight
    this.chartWidth = this.$refs.chartContainer.offsetWidth

    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll)

      document.getElementById('rangeContainer').addEventListener('touchmove', function (event) {
          event.preventDefault()
      })
    })

    this.throttleScroll = throttle(this.handleScroll, false)
    
    if (this.reload) {
        this.$store.commit('policy/setReload', false)
        this.restoreOpts()
      }
  },
  destroyed(){
      window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    fillConditionSatisfied(condition) {
      condition.is_satisfied = true
      for (let regulation of condition.regulations) {
        if (regulation.is_satisfied == false) {
          condition.is_satisfied = false
          break;
        }
      }
    },
    fillRadioOpts(qid, opt) {
      console.log('fillRadioOpts: v-->' + opt)
      let projectSet = this.questionProjectMap.get(qid)
      if (projectSet == undefined)
        return
      
      projectSet.forEach((value) => {
        let rule = value
        for (let condition of rule.conditions) {
          for (let regulation of condition.regulations) {
            if (regulation.qid != qid)
              continue

            for (let v of regulation.values) {
              if (v.opt == opt) {
                regulation.is_satisfied = true
                break
              }
            }
          }

          this.fillConditionSatisfied(condition)
        }
      })
    },
    restoreOpts() {
      this.baseQ.forEach(item => {
      let key = item.id
      let value = this.getOpts(key)
      console.log('key: ' + key + ', value: ' + value)
      if (value != null) {
          let x = value.split(';')
          if (item.type === 'picker') {
            item.pickName = x[0]
            item.pickValue.push(x[1])
          } else if (item.type === 'range') {
            item.rangeValue = parseInt(x[1])
          }
      }
    })

      this.detailQ.forEach(item => {
        let key = item.id
        let value = this.getOpts(key)

        if (value != null) {
          let x = value.split(';')
          item.value = x[1] 
          this.detailQA[key] = x[1]

          this.fillRadioOpts(key, x[1])
        }
      })

      let fee = this.getOpts('totalFee')
      if (fee != null)
        this.$store.commit('policy/updateTotalFee', parseFloat(fee))

      fee = this.getOpts('rsjFee')
      if (fee != null)
        this.$store.commit('policy/updateRSJFee', parseFloat(fee))

      fee = this.getOpts('kwFee')
      if (fee != null)
          this.$store.commit('policy/updateKWFee', parseFloat(fee))

      fee = this.getOpts('jxwFee')
      if (fee != null)
          this.$store.commit('policy/updateJXWFee', parseFloat(fee))

      fee = this.getOpts('zxqyjFee')
      if (fee != null)
          this.$store.commit('policy/updateZXQYJFee', parseFloat(fee))

      fee = this.getOpts('qtFee')
      if (fee != null)
          this.$store.commit('policy/updateQTFee', parseFloat(fee))


      let n = this.getOpts('prjectNum')
      if (n != null)
        this.$store.commit('policy/setProjectNum', parseFloat(n))

      let projectStr = this.getOpts('project')
      if (projectStr != null) {
        let project = projectStr.split(',')
        this.$store.commit('policy/setProjectSet', project)
      }

      if (n != null && n != 0)
        this._doChart();
    },
    getOpts(key) {
      return localStorage.getItem(key)
    },
    saveOpts(key, value) {
      localStorage.setItem(key, value)
    },
    goList() {
      if (this.projectNum != 0) {
        let projectStr = ''
        for (let p of this.project.keys()) {
          projectStr += p
          projectStr += ','
        }

        console.log('goList:' + projectStr.substring(0, projectStr.length-1))
        this.$router.push({name: 'PolicyProjectList', query:{id:projectStr.substring(0, projectStr.length-1)}})
      }
    },
    onRangeAdd() {
      if (this.rangeTempValue == this.rangeValueMax) {
        return;
      }

      this.rangeTempValue += 1;
    },
    onRangeSub() {
      if (this.rangeTempValue == this.rangeValueMin) {
        return;
      }

      this.rangeTempValue -= 1;
    },
    onRadioClick(el) {
      let newValue = el.target.defaultValue
      console.log('onRaidoClick:' + newValue)
      let v = newValue.split('-')
      let oldValue = this.detailQA[v[0]]
      this.detailQA[v[0]] = newValue

      this.saveOpts(v[0], oldValue + ';' + newValue)
      this.calcAllowance(v[0], oldValue, newValue, el.target)
      console.log('id --> ' + v[0])
      console.log('newValue --> ' + newValue)
      console.log('oldValue --> ' + oldValue)

    },
    onBaseQClick(idx, event) {
      this.curTarget = event.target;
      console.log('curTarget:' + this.curTarget)

      this.baseIdx = idx;
      let curBaseQ = this.baseQ[idx];
      this.title = '选择' + curBaseQ.selTitle;
      if (curBaseQ.type == 'picker') {
        this.pickerValue = curBaseQ.pickValue;
        this.pickerData = curBaseQ.options;
        this.showPopPicker = true;
      } else if (curBaseQ.type == 'range') {
        this.rangeValue = curBaseQ.rangeValue;
        this.rangeTempValue = this.rangeValue;
        this.showPopRange = true;
      }
    },
    onPlayMusic() {
      this.$refs.audio.play()
    },
    onMoreClick() {
      this.$store.commit('policy/showDetail')
    },
    onPickerChange(value) {
      console.log('onPickerChange:' + value);
      this.pickerValue = value;
    },
    onPopHide (type) {
      this.showPopPicker = false
      this.showPopRange = false

      if (type) {
        console.log('name:' + this.$refs.picker.getNameValues(this.pickerValue))

        // 答案没有改变，直接返回
        let q = this.baseQ[this.baseIdx] //当前基础问题
        if (q.pickValue == this.pickerValue) {
          return
        }

        q.pickName = this.$refs.picker.getNameValues(this.pickerValue)
        q.pickValue = this.pickerValue

        this.saveOpts(q.id, q.pickName + ';' + q.pickValue)

        this.calcAllowance(q.id, q.pickValue, this.pickerValue, this.curTarget)

        this.$store.commit('policy/showDetail')
      }
    },
    onPopRangeHide(type) {
      this.showPopPicker = false
      this.showPopRange = false

      if (type) {
        let oldValue = this.rangeValue;
        this.rangeValue = this.rangeTempValue;
        let q = this.baseQ[this.baseIdx];
        if (q.rangeValue == this.rangeValue) {
          return;
        }

        q.rangeValue = this.rangeValue;
        this.saveOpts(q.id, oldValue + ';' + q.rangeValue)

        this.calcAllowance(q.id, oldValue, q.rangeValue, this.curTarget);

        this.$store.commit('policy/showDetail');
      }
    },
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop > this.offsetTop) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }

    },
    _doChart() {
      this.chartData[0].percent = this.rsjRatio
      console.log('this.rsjRatio:' + this.rsjRatio)

      this.chartData[1].percent = this.kwRatio
      console.log('this.kwRatio:' + this.kwRatio)

      this.chartData[2].percent = this.jxwRatio
      console.log('this.jxwRatio:' + this.jxwRatio)

      this.chartData[3].percent = this.zxqyjRatio
      console.log('this.zxqyRatio:' + this.zxqyjRatio)

      this.chartData[4].percent = this.qtRatio
      console.log('this.qtRatio:' + this.qtRatio)

      // this.chartData = this.chartData
      console.log(this.$refs.circleChart.repaint)
      this.$nextTick(() => {
        this.$refs.circleChart.rerender()
      })
    },
    _drop(el) {
      this.ball.show = true
      this.ball.el = el
    },
    _beforeDrop(el) {
      if(this.ball.show) {
        let rect = this.ball.el.getBoundingClientRect();

        let x = rect.left - 100
        let y = rect.top - 100
        el.style.display = ''
        el.style.webkitTransform = `translate3d(0,${y}px,0)`;
        el.style.transform =  `translate3d(0,${y}px,0)`;
        let inner = el.getElementsByClassName('inner-hook')[0];
        inner.style.webkitTransform =  `translate3d(${x}px,0,0)`;
        inner.style.transform = `translate3d(${x}px,0,0)`;
      }
    },
    _dropping(el) {
      /* eslint-disable no-unused-vars*/
      let rf = el.offsetHeight;
      this.$nextTick(() => {
        el.style.webkitTransform = 'translate3d(0,0,0)';
        el.style.transform =  'translate3d(0,0,0)';
        let inner = el.getElementsByClassName('inner-hook')[0];
        inner.style.webkitTransform = 'translate3d(0,0,0)';
        inner.style.transform = 'translate3d(0,0,0)';
      });

    },
    _afterDrop(el) {
      this.ball.show = false
      el.style.display = 'none'
    },

    _calcDept(dept, oldValue, newValue) {
      let v = newValue - oldValue
      this.$store.commit('policy/updateTotalFee', v)
      this.saveOpts('totalFee', this.totalFee)

      switch(dept) {
        case 'rsj': //人社局
          this.$store.commit('policy/updateRSJFee', v)
          this.saveOpts('rsjFee', this.rsjFee)
        break;
        case 'kw': //科委
          this.$store.commit('policy/updateKWFee', v)
          this.saveOpts('kwFee', this.kwFee)
        break;
        case 'jxw': //经信委
          this.$store.commit('policy/updateJXWFee', v)
          this.saveOpts('jxwFee', this.jxwFee)
        break;
        case 'zxqyj': //中小企业局
          this.$store.commit('policy/updateZXQYJFee', v)
          this.saveOpts('zxqyjFee', this.zxqyjFee)
        break;
        case 'qt': //其他
          this.$store.commit('policy/updateQTFee', v)
          this.saveOpts('qtFee', this.qtFee)
        break;
      }

      console.log('dept:' + dept)
      console.log('oldValue:' + oldValue)
      console.log('newValue:' + newValue)
      console.log('totalFee:' + this.totalFee)
    },
    _calcFee(calcRule, value) {
      console.log('[_calcFee] calcRule' + calcRule)

      if (calcRule == 'null')
        return 0

      let s = calcRule.replace(/{value}/g, value)
      let r = eval(s)
      console.log('[_calcFee] s:' + s + ' r:' + r)
      return r
    },
    _calcRegulationValue(condition) {
      let result = [0, 0]
      for (let regulation of condition.regulations) {
        console.log('[_calcRegulationValue] regulation.oldValue:' + regulation.oldValue)
        console.log('[_calcRegulationValue] regulation.newValue:' + regulation.newValue)

        for (let value of regulation.values) {
          if (value.opt == regulation.oldValue || value.opt == '') {
            result[0] += this._calcFee(value.calc_rule, regulation.oldValue)
          }
          if (value.opt == regulation.newValue || value.opt == '') {
            result[1] += this._calcFee(value.calc_rule, regulation.newValue)
          }
        }
      }

      return result
    },
    _checkConditionSatisfied(condition, rule, el) {
      let satisfied = true
      for (let regulation of condition.regulations) {
        if (regulation.is_satisfied == false) {
          satisfied = false
          break
        }
      }

      console.log('[_checkConditionSatisfied] condition.is_satisfied:' + condition.is_satisfied)
      console.log('[_checkConditionSatisfied] condition.satisfied:' + satisfied)

      // 原来满足，现在不满足
      if (condition.is_satisfied && !satisfied) {
        condition.is_satisfied = false
        let oldValue = 0
        // 条件不为空，直接计算该值
        if (condition.calc_rule != 'null') {
          oldValue = this._calcFee(condition.calc_rule, 0)
        } else {
          let res = this._calcRegulationValue(condition)
          oldValue = res[0]
        }

        this._calcDept(rule.catalog, oldValue, 0)
        this._deleteProject(rule.id)

      } else if (!condition.is_satisfied && satisfied) {
        condition.is_satisfied = true
        if (condition.calc_rule != 'null') {
          let v = this._calcFee(condition.calc_rule, 0)
          this._calcDept(rule.catalog, 0, v)
        } else {
          let res = this._calcRegulationValue(condition)
          console.log('[_checkConditionSatisfied] 原来不满足，现在满足，res[0]:' + res[0] + ', res[1]:' + res[1])
          // let n = this._calcFee(condition.calc_rule)
          this._calcDept(rule.catalog, res[0], res[1])
        }

        console.log('[_checkConditionSatisfied] 满足:' + rule.title)
        console.log('[_checkConditionSatisfied] 满足:' + condition.is_satisfied + '/' + satisfied)

        // 保存被满足的项目
        this._addProject(rule.id, el)
      }

    },
    _addProject(pid, el) {
      this._doChart()
      this.$store.commit('policy/addProject', pid)
      this.saveOpts('prjectNum', this.projectNum)

      let projectStr = ''
        for (let p of this.project.keys()) {
          projectStr += p
          projectStr += ','
        }

      this.saveOpts('project', projectStr)

       this.onPlayMusic()
      // 钱币动效
      if (el != undefined)
        this._drop(el)
    },
    _deleteProject(pid) {
      this._doChart()
      this.$store.commit('policy/deleteProject', pid)
      this.saveOpts('prjectNum', this.projectNum)

      let projectStr = ''
        for (let p of this.project.keys()) {
          projectStr += p
          projectStr += ','
        }

      this.saveOpts('project', projectStr)
    },
    // 只确定是否满足了选项
    _dealQuestion(qid, oldValue, newValue, regulation, condition, rule) {
      if (regulation.qid != qid) {
        return;
      }

      console.log('[_dealQuestion] regulation.oldValue:' + regulation.oldValue + ', regulation.newValue:' + regulation.newValue)

      regulation.is_satisfied = false
      regulation.oldValue = oldValue
      regulation.newValue = newValue

      for (let value of regulation.values) {
        if (value.opt == '') {
          console.log('[_dealQuestion] 满足' + regulation.qid + ", oldValue:" + oldValue + ", newValue:" + newValue)
          // opt为空字符串时，什么条件都满足，需要改变，让外层函数知道状态有变
          condition.is_satisfied = false
          regulation.is_satisfied = true
        } else {
          console.log('xxxx value.opt:' + value.opt + ', newValue: ' + newValue)
          if (value.opt == newValue) {
            // 增加
           // let n = this._calcFee(value.calc_rule, newValue)
           // this._calcDept(rule.catalog, 0, n)

            console.log('[_dealQuestion] 满足' + regulation.qid + ", oldValue:" + oldValue + ", newValue:" + newValue)
            regulation.is_satisfied = true
          }
        }
      }
    },
    calcAllowance(qid, oldValue, newValue, el) {
      console.log('[calcAllowance] qid:' + qid + ', oldValue:' + oldValue + ", newValue:" + newValue)

      let projectSet = this.questionProjectMap.get(qid)
      if (projectSet == undefined) {
        console.log('[calcAllowance] projectSet is undefined')
        return
      }

      projectSet.forEach((value, key) => {
        let rule = value
        console.log('[calcAllowance] rule.title:' + rule.title)
        for (let condition of rule.conditions) {
          for (let regulation of condition.regulations) {
            this._dealQuestion(qid, oldValue, newValue, regulation, condition, rule)
          }
          this._checkConditionSatisfied(condition, rule, el)
        }
      })
    },
    onClearLS() {
      ++this.clearLSCount
    if (this.clearLSCount > 3) {
        localStorage.clear()
        window.location.reload()
      }
    }
  }
  
};

</script>

<style scoped lang="less">

.bk {
  min-height: 100%;
  background: url('../../../assets/bg.png') no-repeat fixed;
  background-size: cover;
}

.page {
  min-height:100%;
  padding-bottom:50px;
  box-sizing: border-box;
}

.footer {
  font-size:12px;
  color:#666666;
  text-align:center;
  // height:40px;
  margin-top:-30px;
  // margin-bottom:10px;
}

.logo {
  text-align: center;
  font-size:0;
  padding: 5px 0px;
}

.result {
  display: flex;
  margin: 0px 16px 10px;
  height: 180px;
  background-color: #ffffff;
  border-radius: 10px;
}

.chart {
  position: relative;
  display:inline-block;
  margin-left:10px;
  width:55%;
  height:100%;
}

.chart .bt {
  position: absolute;
  top: 53%;
  left:50%;
  transform:translateX(-50%) translateY(-50%);
  color:#333333;
  text-align: center;
}

.chart .bt .title {
  font-size:12px;
}

.chart .bt .fee {
  font-size:30px;
  line-height:31px;
}

.xq-container {
  display:inline-block;
  margin-left:10px;
  margin-top:16px;
}

.xq-list {
  list-style: none;
  font-size:12px;
  color:#666666;
  line-height:12px;
}

.xq-list li {
  text-align:left;
  padding-bottom: 10px;
}

.percent {
  padding-left:6px;
}

.dot {
  display: inline-block;
  position: relative;
  top:-1px;
  width:6px;
  height:6px;
  line-height:6px;
  margin-right:8px;
  border-radius: 50%;
}

.rsj {
  background-color: #01becc;
}

.kw {
  background-color: #f0573d;
}

.zxqy {
  background-color: #ff7d2e;
}

.jxw {
  background-color: #ffb400;
}

.qt {
  background-color: #9a76be
}

.xq-btn {
  display:block;
  position: relative;
  width:100px;
  height:30px;
  color: #fff;
  margin-top:6px;
  text-align: center;
  background-color: #cccccc;
  border-radius: 5px;
  font-size:16px;
  line-height:30px;
}

.xq-btn-active {
  background-color: #147edd;
}

.xq-badge {
  position:absolute;
  top:-10px;
  right:-5px;
}

.isFixed {
  position: fixed;
  left: 0;
  top: 0;
  right:0;
  bottom:0;
  z-index: 2;
  box-shadow: 0 0 10px #888;
  border-radius: 0px;
}

.question {
  margin: 0px 16px 10px;
  min-height: 335px;
  background-color: #ffffff;
  border-radius: 10px;
}

.q-base {
  padding:23px 28px 14px;
}

.q-item {
  display:flex;
  font-size:14px;
  color: #999999;
  height:30px;
  align-items:center;
  margin-bottom:18px;
}

.q-item-title {
  margin-right:8px;
  width:60px;
  text-align:right;
}

.q-item-sel {
  flex:1;
  height:35px;
  line-height:35px;
  padding-left:10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  position: relative;
  color:#333333;
}

.q-item-arrow-down {
  fill: #666666;
  position: absolute;
  top: 50%;
  right: 6px;
  transform:translateY(-50%);
}

.divider {
  margin:0 16px;
  border-top: dashed 1px #cccccc;
}

.more {
  text-align:center;
  font-size:12px;
  color: #999999;
  margin-top:12px;
  position:relative;
}

.more-arrow-down {
  display: block;
  margin: 0 auto;
}

.q-detail {
  margin:0 16px;
  font-size:14px;
  color:#666666;
}

.q-detail-item {
  letter-spacing:1px;
}

.q-detail-item:first-child {
  padding-top:25px;
}
.q-detail-item:last-child {
  padding-bottom:15px;
}

.q-detail-opts {
  padding: 6px 8px;
}

.q-detail-opts-item {
  display:inline-block;
  margin-bottom:14px;
  width: 45%;
}

input[type="radio"]+label::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  position:relative;
  top:-2px;
  width: 1em;
  height: 1em;
  margin-right: .4em;
  border-radius: 50%;
  border: 1px solid #666666;
  text-indent: .15em;
  padding: .1em;
}

input[type="radio"]:checked+label::before {
  background-color: #147edd;
  border: solid 1px #147edd;
  background-clip: content-box;
}

input[type="radio"] {
    position: absolute;
    opacity: 0;
}

label {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.range-title {
  display:flex;
  justify-content:space-between;
  margin-bottom:20px;
  font-size:20px;
  color:#147edd;
}

.ball-container .ball {
  position: fixed;
  top: 100px;
  left: 100px;
  z-index: 200;
  transition: all 1s cubic-bezier(0,1.92,.74,1.01);
}

.ball-container .ball .inner {
  width:50px;
  height:50px;
  background: url('../../../assets/golden.png');
  background-size:50px 50px;
  transition: all 1s linear;
}

.xq-bottom {
  font-size: 16px;

  padding-bottom:20px;
  text-align: center;
}

.project-num {
  color: #147edd;
}

.xq-link {
  color: #147edd;
}
</style>
