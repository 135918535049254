let detailQ = [
	{
		id: 'D1',
		value: '',
		title: '1.营业利润率达到5%，平均增速不低于10%',
		type: 'radio',
		options: [
			{id: 'D11', name: '是', value: 'D1-D11'},
			{id: 'D12', name: '否', value: 'D1-D12'}
		]
	},
	{
		id: 'D2',
		value: '',
		title: '2.主导产品销售收入占本企业销售收入的50%，研发投入占销售收入的2.5%',
		type: 'radio',
		options: [
			{id: 'D21', name: '是', value: 'D2-D21'},
			{id: 'D22', name: '否', value: 'D2-D22'}
		]
	},
	{
		id: 'D3',
		value: '',
		title: '3.主导产品在全国细分市场占有率情况',
		type: 'radio',
		options: [
			{id: 'D31', name: '一般', value: 'D3-D31'},
			{id: 'D32', name: '高且有知名度', value: 'D3-D32'},
			{id: 'D33', name: '国内前五', value: 'D3-D33'},
			{id: 'D34', name: '国内前二', value: 'D3-D34'},
		]
	},
	{

		id: 'D4',
		value: '',
		title: '4.是否完成股份制改造',
		type: 'radio',
		options: [
			{id: 'D41', name: '没有', value: 'D4-D41'},
			{id: 'D42', name: '已完成', value: 'D4-D42'}
		]
	},
	{

		id: 'D5',
		value: '',
		title: '5.是否在重庆股份转让中心挂牌',
		type: 'radio',
		options: [
			{id: 'D51', name: '没挂牌', value: 'D5-D51'},
			{id: 'D52', name: '孵化板挂牌', value: 'D5-D52'},
			{id: 'D53', name: '成长板挂牌', value: 'D5-D53'},
			{id: 'D54', name: '专精特新挂牌', value: 'D5-D54'}
		]
	},
	{
		id: 'D6',
		value: '',
		title:'6.研发人员占企业职工总数10%',
		type:'radio',
		options:[
			{id: 'D61', name:'是', value:'D6-D61'},
			{id: 'D62', name:'否', value:'D6-D62'}
		]
	},
	{
		id: 'D7',
		value: '',
		title:'7.近三年研发费用占销售收入总额的比例不低于5%',
		type:'radio',
		options:[
			{id: 'D71', name:'是', value:'D7-D71'},
			{id: 'D72', name:'否', value:'D7-D72'}
		]
	},
	{
		id: 'D8',
		value: '',
		title:'8.高新技术产品收入占企业当年总收入60%',
		type:'radio',
		options:[
			{id: 'D81', name:'是', value:'D8-D81'},
			{id: 'D82', name:'否', value:'D8-D82'}
		]
	},
	{
		id: 'D9',
		value: '',
		title:'9.产品具有相关的知识产权',
		type:'radio',
		options:[
			{id: 'D91', name:'有', value:'D9-D91'},
			{id: 'D92', name:'无', value:'D9-D92'}
		]
	},
	{
		id: 'D10',
		value: '',
		title:'10.产品有法定标准，可提供50万以上的销售发票',
		type:'radio',
		options:[
			{id: 'D101', name:'是', value:'D10-D101'},
			{id: 'D102', name:'否', value:'D10-D102'}
		]
	},
	{
		id: 'D11',
		value: '',
		title:'11.公司是否拥有知识产权',
		type:'radio',
		options:[
			{id: 'D111', name:'是', value:'D11-D111'},
			{id: 'D112', name:'否', value:'D11-D112'}
		]
	},
	{
		id: 'D12',
		value: '',
		title:'12.是否是相关部门认定的电子商务示范企业',
		type:'radio',
		options:[
			{id: 'D121', name:'国家认定', value:'D12-D121'},
			{id: 'D122', name:'市级认定', value:'D12-D122'},
			{id: 'D123', name:'否', value:'D12-D123'}
		]
	}
]

export default detailQ;